// dependencies
import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

// components
import Select from "@cloudspire/legacy-resources/src/components/Select2";

// constants
import { SEARCH_CAPACITY_LIST } from "@cloudspire/legacy-resources/src/constants/search";

// libraries
import { noop } from "@cloudspire/legacy-shared/src/libraries";

function SelectCapacity(props) {
  const {
    name = "capacity",
    min,
    max,
    value = "",
    selectProps,
    onChange = noop,
  } = props;

  const intl = useIntl();

  let capacityList = SEARCH_CAPACITY_LIST;

  if (max > 0) {
    capacityList = capacityList.filter((capacity) => capacity.id <= max);
  }

  if (min > 0) {
    capacityList = capacityList.filter((capacity) => capacity.id >= min);
  }

  return (
    <Select
      name={name}
      onChange={onChange}
      native={true}
      selectProps={selectProps}
      placeholder={intl.formatMessage({
        id: "front.containers.index.form.field.maxCapacity",
      })}
      value={value}
      optionList={capacityList.map((searchCapacity) => ({
        label: intl.formatMessage(
          { id: "app.components.selectCapacity" },
          {
            adults: searchCapacity.id,
          }
        ),
        value: String(searchCapacity.id),
      }))}
    />
  );
}

SelectCapacity.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  max: PropTypes.number,
  min: PropTypes.number,
  selectProps: PropTypes.object,
  onChange: PropTypes.func,
};

export default SelectCapacity;
