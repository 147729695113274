// constants
import {
  BOOKING_NATURE_VALUE_PAR,
  BOOKING_NATURE_VALUE_PRO,
} from "../../../constants/booking";

/**
 * Retourne la nature selon si la réservation est à but professionnel.
 * @param {object} param0
 * @param {boolean} param0.forWork
 * @param {boolean} [param0.proOnly=false] Si la case est décocher, on retourne une chaîne vide à la place.
 */
export default function bookingGetNatureByForWork({
  forWork,
  proOnly = false,
}) {
  return forWork
    ? BOOKING_NATURE_VALUE_PRO
    : !proOnly
    ? BOOKING_NATURE_VALUE_PAR
    : "";
}
