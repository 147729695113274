/**
 * Retourne la liste des années possibles pour le sélecteur d’âge
 *   des enfants.
 * @param {object} param0
 * @param {number} param0.maxChildrenAge
 */
export default function bookingGetChildrenYearList({ maxChildrenAge }) {
  return Array(maxChildrenAge)
    .fill(null)
    .map((_, index) => {
      const year = new Date(Date.now());
      year.setFullYear(year.getFullYear() + -index);

      return year.getFullYear();
    });
}
