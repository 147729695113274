export const SEARCH_CAPACITY_VALUE_ONE = 1;
export const SEARCH_CAPACITY_VALUE_TWO = 2;
export const SEARCH_CAPACITY_VALUE_THREE = 3;
export const SEARCH_CAPACITY_VALUE_FOUR = 4;
export const SEARCH_CAPACITY_VALUE_FIVE = 5;
export const SEARCH_CAPACITY_VALUE_SIX = 6;
export const SEARCH_CAPACITY_VALUE_SEVEN = 7;
export const SEARCH_CAPACITY_VALUE_HEIGHT = 8;
export const SEARCH_CAPACITY_VALUE_NINE = 9;
export const SEARCH_CAPACITY_VALUE_TEN_AND_MORE = 10;
export const SEARCH_CAPACITY_VALUE_TWENTY_AND_MORE = 20;

export const SEARCH_CAPACITIES = {
  [SEARCH_CAPACITY_VALUE_ONE]: {
    id: SEARCH_CAPACITY_VALUE_ONE,
  },
  [SEARCH_CAPACITY_VALUE_TWO]: {
    id: SEARCH_CAPACITY_VALUE_TWO,
  },
  [SEARCH_CAPACITY_VALUE_THREE]: {
    id: SEARCH_CAPACITY_VALUE_THREE,
  },
  [SEARCH_CAPACITY_VALUE_FOUR]: {
    id: SEARCH_CAPACITY_VALUE_FOUR,
  },
  [SEARCH_CAPACITY_VALUE_FIVE]: {
    id: SEARCH_CAPACITY_VALUE_FIVE,
  },
  [SEARCH_CAPACITY_VALUE_SIX]: {
    id: SEARCH_CAPACITY_VALUE_SIX,
  },
  [SEARCH_CAPACITY_VALUE_SEVEN]: {
    id: SEARCH_CAPACITY_VALUE_SEVEN,
  },
  [SEARCH_CAPACITY_VALUE_HEIGHT]: {
    id: SEARCH_CAPACITY_VALUE_HEIGHT,
  },
  [SEARCH_CAPACITY_VALUE_NINE]: {
    id: SEARCH_CAPACITY_VALUE_NINE,
  },
  [SEARCH_CAPACITY_VALUE_TEN_AND_MORE]: {
    id: SEARCH_CAPACITY_VALUE_TEN_AND_MORE,
  },
  [SEARCH_CAPACITY_VALUE_TWENTY_AND_MORE]: {
    id: SEARCH_CAPACITY_VALUE_TWENTY_AND_MORE,
  },
};

export const SEARCH_CAPACITY_LIST = Object.values(SEARCH_CAPACITIES);
